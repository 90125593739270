<template>
  <div id="app">
    <v-app class="d-flex bg-light">

      <div id="leftNav" ref="left_navigation" class="noprint">
        <navigation v-if="isLoggedIn && !can('pos | salepoint')"/>
      </div>

      <v-main>

        <success-message/>

        <error-message/>

        <confirm-dialog/>

        <PreviewImageDialog/>

        <router-view/>

        <v-btn v-show="fab"
               v-scroll="onScroll"
               bottom
               depressed
               class="noprint"
               color="gifty"
               dark
               fab
               fixed
               right
               small
               @click="toTop">
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>

      </v-main>

      <app-footer v-if="isLoggedIn"/>

    </v-app>
  </div>
</template>
<script>
import Navigation from "@/navigation/navigation";
import AppFooter from "@/navigation/app-footer";
import ConfirmDialog from "@/components/confirm-dialog";
import SuccessMessage from "@/components/successMessage";
import ErrorMessage from "@/components/errorMessage";

import PreviewImageDialog from "./components/PreviewImageDialog.vue";

export default {
  components: {
    ErrorMessage,
    SuccessMessage,
    ConfirmDialog,
    AppFooter,
    Navigation,
    PreviewImageDialog,
  },
  data() {
    return {
      fab: false,
      isLoggedIn: this.$store.state.isLoggedIn,
      sideBarWidth: 0
    };
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },

  /**
   * Disable zoom
   */
  mounted() {
    window.addEventListener('keydown', function (e) {
      if (e.ctrlKey && (e.key === '+' || e.key === '-' || e.key === '0')) {
        e.preventDefault();
      }
    });
    window.addEventListener('wheel', function (e) {
      if (e.ctrlKey) {
        e.preventDefault();
      }
    }, {passive: false});
    document.addEventListener('gesturestart', function (e) {
      e.preventDefault();
    });
    document.addEventListener('touchstart', function (e) {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    });
    document.addEventListener('touchmove', function (e) {
      if (e.scale !== 1) {
        e.preventDefault();
      }
    });
  }
};
</script>

<style lang="scss">
</style>
