<template>
    <div>

        <!--**
        Desktop navigation*
        **-->
        <v-navigation-drawer v-if="!mobileDrower"
                             :mini-variant="miniVariant"
                             app
                             class="navigation"
                             dark
                             mini-variant-width="80">
            <vue-scroll :ops="ops">
                <div @mouseleave="mouseLeave" @mouseover="mouseOver">
                    <sidebar :mini-variant="miniVariant"/>
                </div>
            </vue-scroll>

        </v-navigation-drawer>

        <navbar @miniVariant="miniVariant = !miniVariant" @mobileDrower="mobileDrower = !mobileDrower"/>

        <!--**
        Mobile navigation*
        **-->
        <v-navigation-drawer v-if="mobileDrower"
                             v-model="mobileDrower"
                             app
                             class="navigation"
                             dark>
            <vue-scroll :ops="ops">
                <div @mouseleave="mouseLeave" @mouseover="mouseOver">
                    <sidebar :mini-variant="false"/>
                </div>
            </vue-scroll>
        </v-navigation-drawer>

    </div>
</template>

<script>
import Sidebar from "@/navigation/sidebar";
import Navbar from "@/navigation/navbar";

export default {
    components: {Sidebar, Navbar},
    data() {
        return {
            ops: {
                bar: {
                    opacity: 0.6,
                    background: "#fff2f9",
                    size: '6px',
                    keepShow: false,

                },
                scrollPanel: {
                    scrollingX: false,
                }
            },
            miniVariant: this.$store.state.user?.user?.role?.name === "store",
            mobileDrower: false,
        }
    },
    methods: {
        mouseOver() {
            this.ops.bar.keepShow = true
        },
        mouseLeave() {
            this.ops.bar.keepShow = false
        }
    }
}
</script>
