<template>
  <div>
    <v-footer v-show="showFooter"
              ref="footer"
              color="#f0f1f7">
      <v-col class="text-right" cols="12">
        © Copyright {{ new Date().getFullYear() }}
        <strong class="gifty--text">Gifty DZ</strong> Tous droits réservés.
      </v-col>
    </v-footer>
  </div>
</template>

<script>
export default {
    computed: {
        showFooter() {
            let path_name = this.$route.name;
            return !["e_payment.message", "e_payment.checkout"].includes(path_name);
        },
    }
}
</script>

<style scoped>

</style>